import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Input, Button, CardText, FormFeedback } from "reactstrap";
import MetaTags from 'react-meta-tags';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { searchCustomer } from "store/actions";
import toastr from "toastr";
import { postWeb, post } from "helpers/api_helper";
import Pages404 from "pages/Utility/pages-404";
import { myParseFloat } from "helpers/validationHelper";

const FreeMoneySupport = () => {
  const dispatch = useDispatch();
  const { users, searchedCustomer } = useSelector(state => ({
    users: state.Users.users,
    searchedCustomer: state.Customers.searchedCustomer
  }));
  const initialStateOfErrors = {
    notFound: '',
    customerID: '',
    newFreeMoney: '',
    approvedBy: '',
    // suggestedBy:'',
    reason: '',
    csvFile: '',
  };

  const [customerData, setCustomerData] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [newFreeMoney, setNewFreeMoney] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  // const [suggestedBy, setSuggestedBy] = useState("");
  const [reason, setReason] = useState("");
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(initialStateOfErrors);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [isCsvValid, setIsCsvValid] = useState(false); 
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (typeof searchedCustomer == 'object' && Object.keys(searchedCustomer).length) {
      setCustomerData(searchedCustomer);
    }
    else if(customerID && !Object.keys(searchedCustomer).length){
      setError({...error, notFound:'Customer details not found'})
    }
  }, [searchedCustomer]);

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users]);

  useEffect(() => {
    setCustomerData({})
    setCustomerID('')
    if (!isBulkUpload) {
      setCsvFile(null)
      setIsCsvValid(false)
    }
  }, [isBulkUpload])
  useEffect(() => {
    if (customerID) {
      setCustomerData({});
    }
  }, [customerID]);  

  const handleSearch = async () => {
    setLoading(true)
    setCustomerData({})
    setError(initialStateOfErrors)
    setApprovedBy('')
    setNewFreeMoney('')
    setReason('')
    // setSuggestedBy('')
    if (customerID) {
      dispatch(searchCustomer({customerID}));
    } else {
      setError({...error, customerID:'Please enter a valid customer ID'})
    }
    setLoading(false)
  };
  const handleFreeMoneyChange = (e) => {
    let value = e.target.value;

    // Allowing user to clear the input field
    if (value === '') {
      setError(initialStateOfErrors);
      setNewFreeMoney('');
      return;
    }

    // Check if the value is a number and greater than 0 else return
    if(isNaN(value) || value <= 0){
      return;
    }

    setError(initialStateOfErrors);
    setNewFreeMoney(value);
  };

  const handleChange = (e) => {
    setError(initialStateOfErrors);
    const value = e.target.value;
    setCustomerID(value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpdate = async () => {
    setLoading(true)
    if(!customerID || customerID == ''){
      setError({...error,customerID:"Please enter Customer ID."});
      setLoading(false)
      return;
    }
    if (!newFreeMoney) {
      setError({...error,newFreeMoney:"Please enter the free money amount to be added."});
      setLoading(false)
      return }
    if (!approvedBy) {
      setError({...error,approvedBy:"Please enter the approver's name."});
      setLoading(false)
      return;
    }
    // if (!suggestedBy) {
    //   setError({...error,suggestedBy:"Please enter the suggester's name."});
    //  setLoading(false)
    //   return;
    // }
     else if(!reason){
      setError({...error,reason:"Please enter reason."});
      setLoading(false)
      return;
    }
    if (newFreeMoney && reason) {
      try {
        const response = await postWeb("customers/addFreeMoney", { customerID, amountToAdd: newFreeMoney, approvedBy, reason });

        if (response.statusCode == 200) {
          toastr.success("Free money amount updated successfully.");
          setNewFreeMoney("");
          setApprovedBy("");
        //setSuggestedBy("");
          setReason("");
        setCustomerID("");
        } else if (response.statusCode == 400) {
            //For free money amount limit.
          if (response?.msg && response.msg.includes("Amount should be less than")) {
            setError({ ...error, newFreeMoney: response?.msg });
          } else {
            toastr.error(response?.msg ?? "An error occurred while updating the free money amount.");
            }
        }
      } catch (error) {
        toastr.error("An error occurred while updating the free money amount.");
      } finally{
       setLoading(false)
      }
    } else {
      toastr.error("Please fill in all fields.");
      setLoading(false)
    }
  };

  const handleCsvChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/csv") {
      setCsvFile(file);
      setIsCsvValid(true); // CSV is valid
      setError({ ...error, csvFile: '' });
    } else {
      setCsvFile(null);
      setIsCsvValid(false); // CSV is not valid
      setError({ ...error, csvFile: 'Please upload a valid .csv file.' });
    }
  };
  const downloadSampleCsv = () => {
    const sampleCsvContent = 'CustomerID,Amount\n';
    const blob = new Blob([sampleCsvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sample.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

    // Step 1: Initialize state for the checkbox
    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };


  const handleBulkUpload = async (e) => {
    e.preventDefault();

    if (!approvedBy) {
      setError({ ...error, approvedBy: "Please enter the approver's name." });
      return;
    }
    if (!reason) {
      setError({ ...error, reason: "Please enter reason." });
      return;
    }
    if (!csvFile) {
      setError({ ...error, csvFile: "Please upload a CSV file." });
      return;
    }

    // Reset error state before making API call
    setError({});

    const formData = new FormData();
    formData.append('file', csvFile);
    formData.append('approvedBy', approvedBy);
    formData.append('reason', reason);
    formData.append('notify',isChecked)

    try {
      const response = await post('customers/addFreeMoney', formData, true) 
     if(response?.statusCode == 200){
      toastr.success(`Bulk upload successful!`);
     } else{
      toastr.error(response?.msg || "Failed to Upload!");
     }
    } catch (error) {
      console.log("ERRROR",error)
      toastr.error(error?.response?.data?.msg || "Failed to upload!");
    }
  };

  if (userData?.permissions?.allPermissions === true || userData?.permissions?.supportFreeMoney?.update === true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Kleen | Free Money Support</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Support Tools" breadcrumbItem="Free Money Support" />
            <Row className="d-flex align-items-center" style={{ minHeight: '50px' }}>
              <Col sm="12">
                <div className="d-flex align-items-center">
                  <label className="d-flex align-items-center">
                    <input
                      type="radio"
                      checked={!isBulkUpload}
                      onChange={() => setIsBulkUpload(false)}
                    />
                    <span className="ms-2">Single Transaction</span>
                  </label>
                  <label className="ms-4 d-flex align-items-center">
                    <input
                      type="radio"
                      checked={isBulkUpload}
                      onChange={() => setIsBulkUpload(true)}
                    />
                    <span className="ms-2">Bulk Transfer Free Money</span>
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {isBulkUpload ? (
                      // Bulk Upload UI
                      <Row className="mt-3">
                        <Col sm="12">
                          <input
                            type="file"
                            accept=".csv"
                            onChange={handleCsvChange}
                            ref={fileInputRef}
                            hidden
                          />
                          {error?.csvFile && <span className="text-danger">{error.csvFile}</span>}

                          <Col sm="4">
                            <Button
                              color="primary"
                              className="p-2 text-left  d-flex justify-content-between align-items-center w-max"
                              onClick={handleUploadClick} 
                            >
                             <i className="fa fa-upload h-4 w-4 px-1 mr-1"></i> 
                             <span>
                             Upload CSV File
                             </span> 
                            </Button>

                            <Button
                            style={{marginLeft:'-10px !important'}}
                              color="link"
                              className="outline-0 p-0 mt-2 text-left btn-sm d-flex justify-content-between align-items-center w-max"
                              onClick={downloadSampleCsv}
                            >
                              <i className="fa fa-download h-4 w-4 px-1"></i>
                              <span>Download Sample CSV</span>
                            </Button>
                          </Col>

                          {isCsvValid && (
                            <>
                              <Row className="mt-3">
                                <Col sm="12">
                                  <div className="me-2 mb-2 d-inline-block">
                                    <CardText className="mb-2 pt-2 fs-5">Approved By: </CardText>
                                    <div className="input-group">
                                      <Input
                                        type="text"
                                        placeholder="Enter Approver Name"
                                        value={approvedBy}
                                        invalid={error?.approvedBy?.length > 0}
                                        onChange={(e) => setApprovedBy(e.target.value)}
                                      />
                                    </div>
                                    {error?.approvedBy?.length > 0 && <span className="text-danger">{error?.approvedBy}</span>}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col sm="12">
                                  <div className="me-2 mb-2 d-inline-block">
                                    <CardText className="mb-2 pt-2 fs-5">Reason: </CardText>
                                    <div className="input-group">
                                      <Input
                                        type="text"
                                        placeholder="Enter Reason"
                                        value={reason}
                                        invalid={error?.reason?.length > 0}
                                        onChange={(e) => setReason(e.target.value)}
                                      />
                                    </div>
                                    {error?.reason?.length > 0 && <span className="text-danger">{error?.reason}</span>}
                                  </div>
                                </Col>
                                <Col className="d-flex align-items-center pr-2">
                                  <input
                                    type="checkbox"
                                    className="mr-10"
                                    checked={isChecked} 
                                    onChange={handleCheckboxChange}
                                  />
                                  <span className="mr-5">
                                    Send Notification to Customers
                                  </span>
                                </Col>
                              </Row>
                              <Button
                                className="mt-3"
                                color="success"
                                onClick={handleBulkUpload}
                              >
                                Submit
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      // Single Transaction UI
                      <>
                        <Row className="mb-3">
                          <Col sm="12">
                            <div className="me-2 mb-2 d-inline-block">
                              <div className="position-relative d-flex gap-1 justify-content-evenly align-items-center">
                                <CardText className="mb-2 pt-2 fs-6">Customer ID: </CardText>
                                <div className="mx-2 position-relative w-50">
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Customer ID"
                                    value={customerID}
                                    invalid={error?.customerID?.length > 0}
                                    onChange={handleChange}
                                    onKeyPress={handleKeyPress}
                                  />
                                  {error?.customerID?.length > 0 && (
                                    <span className="text-danger">{error?.customerID}</span>
                                  )}
                                </div>
                                <Button
                                  color="primary"
                                  className="btn btn-primary btn-block mt-1 mb-1"
                                  onClick={handleSearch}
                                  disabled={loading}
                                >
                                  Search
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {customerData?._id ? (
                          <>
                            <Col sm="12">
                              <Row className="d-flex">
                                <Col sm="4" className="d-flex gap-1">
                                  <CardText className="mb-2 pt-2 fs-5 fw-bold">Wallet:</CardText>
                                  <CardText className="mb-2 pt-2 fs-5">{customerData.wallet || "0"}</CardText>
                                </Col>
                                <Col sm="4" className="d-flex gap-1">
                                  <CardText className="mb-2 pt-2 fs-5 fw-bold">Gift Balance:</CardText>
                                  <CardText className="mb-2 pt-2 fs-5">{customerData.giftBalance || "0"}</CardText>
                                </Col>

                              </Row>

                              <Row sm={12}>
                                <Col sm="4" className="mt-2">
                                  <div className="me-2 mb-2 d-inline-block">
                                    <CardText className="mb-2 pt-2 fs-5">Add Free Money: </CardText>
                                    <div className="input-group">
                                      <Input
                                        type="text"
                                        placeholder="Free Money Amount"
                                        value={newFreeMoney}
                                        invalid={error?.newFreeMoney?.length > 0}
                                        onChange={handleFreeMoneyChange}
                                      />
                                    </div>
                                    {error?.newFreeMoney?.length > 0 && <span className="text-danger">{error?.newFreeMoney}</span>}
                                  </div>
                                </Col>
                                <Col sm="4" className="mt-2">
                                  <div className="me-2 mb-2 d-inline-block">
                                    <CardText className="mb-2 pt-2 fs-5">Approved By: </CardText>
                                    <div className="input-group">
                                      <Input
                                        type="text"
                                        placeholder="Enter Approver Name"
                                        value={approvedBy}
                                        invalid={error?.approvedBy?.length > 0}
                                        onChange={(e) => setApprovedBy(e.target.value)}
                                      />
                                    </div>
                                    {error?.approvedBy?.length > 0 && <span className="text-danger">{error?.approvedBy}</span>}
                                  </div>
                                </Col>
                                <Col sm="4" className="mt-2">
                                  <div className="me-2 mb-2 d-inline-block">
                                    <CardText className="mb-2 pt-2 fs-5">Reason: </CardText>
                                    <div className="input-group">
                                      <Input
                                        type="text"
                                        placeholder="Enter Reason"
                                        value={reason}
                                        invalid={error?.reason?.length > 0}
                                        onChange={(e) => setReason(e.target.value)}
                                      />
                                    </div>
                                    {error?.reason?.length > 0 && <span className="text-danger">{error?.reason}</span>}
                                  </div>
                                </Col>
                              </Row>

                              <Button
                                className="mt-3"
                                color="success"
                                onClick={handleUpdate}
                                disabled={loading}
                              >
                                Add Free Money
                              </Button>
                            </Col>
                          </>
                        ) : (
                          <div className="text-danger mt-2">{error.notFound}</div>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return  <Pages404/> ;
  }
};

export default FreeMoneySupport;
