import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import MetaTags from "react-meta-tags";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getLaundrys } from "../../store/laundry/actions";
import { getClothesList } from "../../store/clothes/actions";
import { getInvoiceLogs } from "../../store/invoiceLogs/actions";
import Skeleton from "react-loading-skeleton";
import { withTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import JsonTableViewer from "./JsonTableViewer";

const InvoiceLogs = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    searchText: "",
    selectedLaundry: null,
    currentPage: 1,
    pageSize: 10,
    loading: true,
    fromDate: undefined,
    toDate: undefined,
    modalOpen: false,
    selectedLog: null,
    selectedFilter: { label: 'Edited Orders', value: 'pos_order' },
  });

  const filters = [
    { label: 'Edited Orders', value: 'pos_order' },
    { label: 'Customer Mobile Changes', value: 'customer_mobile' },
    { label: 'Cancelled Orders', value: 'cancel_order' },
    { label: 'Free Money', value: 'free_money' },
  ];

  const { invoiceLogs, LaundryList, totalCount, clothes, lastFetched } = useSelector((state) => ({
    invoiceLogs: state.InvoiceLogs.logs,
    LaundryList: state.Laundry.LaundryList,
    totalCount: state.InvoiceLogs.totalRecords,
    clothes: state.Clothes.clothesList,
    lastFetched: state.InvoiceLogs.lastFetched
    
  }));

  useEffect(() => {
    dispatch(getLaundrys({ web: true }));
    dispatch(getClothesList());
    fetchLogs();
  }, []);

  
  useEffect(() => {
    setState((prev) => ({ ...prev, loading: false }));
  }, [lastFetched])


  useEffect(() => {
    fetchLogs();
  }, [state.searchText, state.selectedLaundry, state.selectedFilter, state.currentPage, state.pageSize]);

  const fetchLogs = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      // if (['cancel_order', 'pos_order'].includes(state.selectedFilter.value)) {
      //   setState((prev) => ({ ...prev, selectedLaundry: null }));
      // }
      const payload = {
        searchText: state.searchText,
        pageNo: state.currentPage, 
        pageSize: state.pageSize, 
        fromDate: state.fromDate, 
        toDate: state.toDate, 
        selectedFilter: state.selectedFilter.value,
        laundryId: state.selectedLaundry?.value
      };
      dispatch(getInvoiceLogs(payload));
    } finally {
      
    }
  };

  

  const handlePageChange = (page) => setState((prev) => ({ ...prev, currentPage: page }));
  const handleRowsPerPageChange = (newPageSize) => setState({ ...state, pageSize: newPageSize, currentPage: 1 });

  const getColumns = () => {
    const baseColumns = [
      { name: "CustomerID", selector: row => row?.customerDetails?.customerID || "NA", sortable: true, style: { textAlign: 'center' } },
      { name: "Name", selector: row => row?.customerDetails?.name || "NA", sortable: true, style: { textAlign: 'center' } },
      { name: "View", cell: row => <Button className="btn btn-primary btn-sm" onClick={() => handleViewClick(row)}>View</Button>, style: { textAlign: 'center' } },
    ];
  
    switch (state.selectedFilter.value) {
      case 'customer_mobile':
        return [
          baseColumns[0],
          baseColumns[1],
          { name: "Old Mobile", selector: row => row?.oldValues?.mobile || "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "New Mobile", selector: row => row?.newValues?.mobile || "NA", sortable: true, style: { textAlign: 'center' } },
        { name: "Updated At", selector: row => moment(row.createdAt).format('MMM/DD/YYYY HH:mm') || "--", sortable: true, style: { textAlign: 'center' } },
          baseColumns[2],
        ];
      case 'free_money':
        return [
          baseColumns[0],
          baseColumns[1],
          { name: "Free Money Given", selector: row => row?.data?.amount || "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "Approved By", selector: row => row?.approvedBy || "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "Notes", selector: row => row?.notes || "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "DateTime", selector: row => moment(row.createdAt).format('MMM/DD/YYYY HH:mm') || "--", sortable: true, style: { textAlign: 'center' } },
          baseColumns[2],
        ];
      case 'cancel_order':
        return [
          { name: "Order ID", selector: row => row?.orderDetails?.orderID || "--", sortable: true, style: { textAlign: 'center' } },
          { name: "Customer Name", selector: row => row?.customerDetails?.name || "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "Customer ID", selector: row => row?.customerDetails?.customerID || "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "Reason For Cancellation", selector: row => row?.notes || "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "Approved By", selector: row => row?.approvedBy || "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "Cancelled At", selector: row => moment(row.createdAt).format('MMM/DD/YYYY HH:mm') || "--", sortable: true, style: { textAlign: 'center' } },
          { name: "View", cell: row => <Button className="btn btn-primary btn-sm" onClick={() => handleViewClick(row)}>View</Button>, style: { textAlign: 'center' } },
        ];
      default:
        return [
          { name: "Order ID", selector: row => row?.orderDetails?.orderID || "--", sortable: true, style: { textAlign: 'center' } },
          { name: "Category", selector: row => row?.orderDetails?.category || "--", sortable: true, style: { textAlign: 'center' } },
          { name: "CustomerId", selector: row => row?.customerDetails?.customerID || row?.newValues?.customerID || "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "Mobile", selector: row => row?.customerDetails?.mobile ?? row?.newValues?.mobile ?? "NA", sortable: true, style: { textAlign: 'center' } },
          { name: "Laundry Name", selector: row => row?.laundryDetails?.nameEn || "--", sortable: true, style: { textAlign: 'center' } },
          { name: "Updated At", selector: row => row?.createdAt ? moment(row.createdAt).format('MMM/DD/YYYY HH:mm') : "--", sortable: true, style: { textAlign: 'center' } },
          { name: "Changes", cell: row => <Button className="btn btn-primary btn-sm" onClick={() => handleViewClick(row)}>View</Button>, style: { textAlign: 'center' } },
        ];
    }
  };  

  const handleViewClick = (log) => {
    setState((prev) => ({ ...prev, selectedLog: log, modalOpen: true }));
  };

  const toggleModal = () => {
    setState((prev) => ({ ...prev, modalOpen: !prev.modalOpen }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Invoice Logs | KLEEN ADMIN PANEL</title>
        </MetaTags>
        <Container fluid>
          
        <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Audit Logs")}
          />
          <Card>
            <CardBody>
              <Row className="mb-2">
                <Col sm="12">
                  <div className="d-flex mb-2">
                    <Input
                      type="text"
                      placeholder="Search"
                      value={state.searchText}
                      onChange={(e) => setState((prev) => ({ ...prev, searchText: e.target.value }))}
                      className="me-2"
                      style={{ width: "20%" }}
                    />
                    <Select
                      placeholder="Filters"
                      value={state.selectedFilter}
                      onChange={(selected) => setState((prev) => ({ ...prev, selectedFilter: selected || filters[0] }))}
                      isClearable
                      options={filters}
                      className="me-2 w-25"
                      style={{ maxWidth: "20% !important" }}
                    />
                    {['free_money', 'customer_mobile'].includes(state.selectedFilter.value) ? null : (
                      <Select
                        placeholder="All Laundries"
                        value={state.selectedLaundry}
                        onChange={(selected) => setState((prev) => ({ ...prev, selectedLaundry: selected }))}
                        isClearable
                        options={LaundryList.map((item) => ({ label: item.nameEn, value: item._id }))}
                        className="me-2 w-25"
                        style={{ width: "20% !important" }}
                      />
                    )}
                    <Input
                      type="date"
                      value={state.fromDate}
                      onChange={(e) => setState((prev) => ({ ...prev, fromDate: e.target.value }))}
                      defaultValue={moment().add(-1, "M").tz("Asia/kuwait").format("YYYY-MM-DD")}
                      className="me-2"
                      style={{ width: "20%" }}
                    />
                    <Input
                      type="date"
                      value={state.toDate}
                      onChange={(e) => setState((prev) => ({ ...prev, toDate: e.target.value }))}
                      defaultValue={moment().tz("Asia/kuwait").format("YYYY-MM-DD")}
                      className="me-2"
                      style={{ width: "20%" }}
                    />
                    <Button
                      type="button"
                      color="success"
                      onClick={fetchLogs}
                    >
                      Search
                    </Button>
                  </div>
                </Col>
              </Row>
              {state.loading == true?
              <div className="row">
              <div className="col-xl-12">
                <div className="table-responsive">
                  <div className="react-bootstrap-table">
                    <table className="table table align-middle table-nowrap">
                      <tbody>
                        
                          <tr count={10}>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                            <td><Skeleton count={1} /></td>
                          </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>:
              <DataTable
                columns={getColumns()}
                data={invoiceLogs}
                pagination
                progressPending={state.loading}
                progressComponent={<Skeleton height={50} count={5} />}
                paginationTotalRows={totalCount}
                paginationPerPage={state.pageSize}
                paginationServer
                paginationDefaultPage={state.currentPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
              />
            }
            </CardBody>
          </Card>
        </Container>
      </div>

      {/* Modal for viewing changes */}
      <Modal isOpen={state.modalOpen} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Field Changes</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <JsonTableViewer
                oldData={state.selectedLog?.oldValues}
                newData={state.selectedLog?.newValues}
                clothes={clothes}
                transactionType={state.selectedLog?.transactionType}
                data={state.selectedLog}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

InvoiceLogs.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(InvoiceLogs);
